import { onlineCanvasService } from './canvasService.online'
import { deleteAllLocalHypotheses } from '@/hypotheses/hypothesesService.local'
import { columnSettingsService } from '@/hypotheses/columnSettings'
import { Canvas } from './typedef'
import * as localforage from 'localforage'

export const LS_LOCAL_CANVAS_KEY = 'local'
export const LS_DEMO_CANVAS_KEY = 'demo'

export async function deleteLocalCanvas() {
  await Promise.all([
    localforage.removeItem(LS_LOCAL_CANVAS_KEY),
    localforage.removeItem(LS_DEMO_CANVAS_KEY),
    deleteAllLocalHypotheses(),
    columnSettingsService.deleteAllFilters(LS_LOCAL_CANVAS_KEY),
    columnSettingsService.deleteAllFilters(LS_DEMO_CANVAS_KEY),
  ])
}

async function createCanvas(canvasId: string): Promise<Canvas> {
  const canvas =
    canvasId === LS_DEMO_CANVAS_KEY
      ? {
          canvas_id: LS_DEMO_CANVAS_KEY,
          created_at: new Date().toISOString(),
          description: 'A demo canvas for the business model of AirBNB.',
          title: 'AirBNB',
          owner_id: 'local',
          updated_at: new Date().toISOString(),
        }
      : {
          canvas_id: LS_LOCAL_CANVAS_KEY,
          created_at: new Date().toISOString(),
          description:
            'Your local canvas. Play with it and get a feel for the app.',
          title: 'Local Canvas',
          owner_id: 'local',
          updated_at: new Date().toISOString(),
        }

  await localforage.setItem(canvasId, canvas)

  return canvas
}

export const localCanvasService: typeof onlineCanvasService = {
  async create(): Promise<
    { success: true; canvas: Canvas } | { success: false; canvas: null }
  > {
    return {
      success: true,
      canvas: await createCanvas(LS_LOCAL_CANVAS_KEY),
    }
  },

  async deleteCanvas(): Promise<{ success: boolean }> {
    await localforage.removeItem(LS_LOCAL_CANVAS_KEY)

    return {
      success: true,
    }
  },

  async updateCanvas(
    _canvasId: string,
    diff: Pick<Canvas, 'description' | 'title'>,
  ): Promise<{ success: boolean }> {
    const canvas = await localforage.getItem<Canvas>(LS_LOCAL_CANVAS_KEY)

    if (!canvas) {
      return { success: false }
    }

    await localforage.setItem(LS_LOCAL_CANVAS_KEY, {
      ...canvas,
      ...diff,
    })

    return {
      success: true,
    }
  },

  async fetchAll(): Promise<{ canvases: Canvas[]; success: boolean }> {
    const canvases = await Promise.all([
      this.fetchSingle(LS_LOCAL_CANVAS_KEY).then((r) => r.canvas),
      this.fetchSingle(LS_DEMO_CANVAS_KEY).then((r) => r.canvas),
    ])

    const filtered = canvases.filter((c): c is Canvas => !!c)

    if (!filtered.length) {
      return {
        canvases: [],
        success: true,
      }
    } else {
      return {
        success: true,
        canvases: filtered,
      }
    }
  },

  async fetchSingle(
    canvasId: string,
  ): Promise<{ success: boolean; canvas: Canvas | null }> {
    const canvas = await localforage.getItem<Canvas>(canvasId)

    if (!canvas) {
      return {
        success: true,
        canvas: await createCanvas(canvasId),
      }
    } else {
      return {
        success: true,
        canvas,
      }
    }
  },
}
