import { useCallback, useLayoutEffect, useRef } from 'react'
import { useRevalidator } from 'react-router-dom'

export function useOptimistic(resetFn: () => void): { revalidate: () => void } {
  const revalidator = useRevalidator()

  const state = revalidator.state

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resetCb = useCallback(() => resetFn(), [])

  const shouldIgnore = useRef(false)
  useLayoutEffect(() => {
    if (state !== 'idle') {
      shouldIgnore.current = false
    }
    if (!shouldIgnore.current && state === 'idle') {
      shouldIgnore.current = true
      resetCb()
    }
  }, [state, resetCb])

  return {
    revalidate: revalidator.revalidate,
  }
}
