import { Edit, Pen, Trash } from 'lucide-react'
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuRadioGroup,
  DropdownMenuLabel,
  DropdownMenuRadioItem,
} from '@/components/ui/dropdown-menu'
import { useState } from 'react'
import { getHypothesesService } from './hypothesesService'
import { uuid } from '@/lib/utils'
import { HypothesesEdit } from './hypotheses-edit'
import { useOptimistic } from '@/hooks/useOptimistic'
import { useUserStore } from '@/stores/user'
import { useSingletonStore } from '@/stores/singletons'
import { Hypotheses, HypothesesState, HypothesesToRender } from './typedef'
import { HypothesesDisplay } from './hypotheses-display'
import {
  HypothesesProps,
  HypothesesDeleteAlert,
} from './hypotheses-delete-alert'

export function HypothesesItem(props: HypothesesProps) {
  const user = useUserStore((state) => state.user)

  const [deleteMode, setDeleteMode] = useState(false)
  const [updateMode, _setUpdateMode] = useState<
    'major_update' | 'minor_update' | ''
  >('')
  const openId = `update_${props.hypotheses.hypotheses_id}`
  const { setOpenSingletonInput, openSingletonInput } = useSingletonStore()
  function setUpdateMode(mode: 'major_update' | 'minor_update' | '') {
    if (!mode) {
      setOpenSingletonInput('')
      return
    } else {
      setOpenSingletonInput(openId)
    }

    _setUpdateMode(mode)
  }

  const [optimisticRefutedHypotheses, setOptimisticRefutedHypotheses] =
    useState<null | HypothesesToRender>(null)
  const [optimisticIsDeleted, setOptimisticIsDeleted] = useState(false)
  const [optimisticUpdatedContent, setOptimisticUpdatedContent] = useState('')
  const [optimisticHypothesesStatus, setOptimisticHypothesesStatus] = useState<
    HypothesesState | ''
  >('')

  const revalidator = useOptimistic(() => {
    setOptimisticHypothesesStatus('')
    setOptimisticIsDeleted(false)
    setOptimisticUpdatedContent('')
    setOptimisticRefutedHypotheses(null)
  })

  const hypotheses: Hypotheses = {
    ...props.hypotheses,
    status: optimisticHypothesesStatus || props.hypotheses.status,
    content: optimisticUpdatedContent || props.hypotheses.content,
  }

  async function updateStatus(status: HypothesesState) {
    setOptimisticHypothesesStatus(status)

    const { success } = await getHypothesesService(!user).minorUpdate(
      props.hypotheses.hypotheses_id,
      { status, content: props.hypotheses.content },
    )

    if (success) {
      revalidator.revalidate()
    } else {
      setOptimisticHypothesesStatus('')
    }
  }

  if (updateMode && openSingletonInput === openId) {
    return (
      <li className="pb-4 pt-2">
        <HypothesesEdit
          updateMode={updateMode}
          hypotheses={props.hypotheses}
          optimisticUpdateContent={(c) => {
            if (updateMode === 'major_update') {
              // the newly generated hypotheses should start in a ToValidate state,
              // regardless of what the "copied/major_updated" hypotheses had
              setOptimisticHypothesesStatus(HypothesesState.ToValidate)
              setOptimisticRefutedHypotheses({
                content: hypotheses.content,
                status: HypothesesState.Refuted,
                hypotheses_id: uuid(),
              })
            }
            setOptimisticUpdatedContent(c)
          }}
          undoOptimistic={() => {
            if (updateMode === 'major_update') {
              setOptimisticRefutedHypotheses(null)
            }
            setOptimisticUpdatedContent('')
          }}
          onClose={() => setUpdateMode('')}
        />
      </li>
    )
  }

  return (
    <>
      {/**
       * If a user performs a major update, the original hypotheses will be marked as refuted,
       * and a new hypotheses with the updated content will be created.
       * The below `optimisticInvalidatedHypotheses` displays the refuted hypotheses until a reload happens.
       */}
      {optimisticRefutedHypotheses && (
        <li>
          <HypothesesDisplay hypotheses={optimisticRefutedHypotheses} />
        </li>
      )}
      <li hidden={optimisticIsDeleted}>
        <DropdownMenu>
          <DropdownMenuTrigger>
            <HypothesesDisplay hypotheses={hypotheses} />
          </DropdownMenuTrigger>

          <DropdownMenuContent align="start" className="w-40">
            <DropdownMenuItem
              onClick={() => {
                setUpdateMode('major_update')
              }}
            >
              <Edit className="mr-2 h-4 w-4" />
              Major Update
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() => {
                setUpdateMode('minor_update')
              }}
            >
              <Pen className="mr-2 h-4 w-4" />
              Correct Typo
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuRadioGroup
              onValueChange={(v) => {
                updateStatus(v as HypothesesState)
              }}
              value={props.hypotheses.status}
            >
              <DropdownMenuLabel inset>Status</DropdownMenuLabel>
              <DropdownMenuRadioItem value={HypothesesState.ToValidate}>
                To Validate
              </DropdownMenuRadioItem>
              <DropdownMenuRadioItem value={HypothesesState.Refuted}>
                Refuted
              </DropdownMenuRadioItem>
              <DropdownMenuRadioItem value={HypothesesState.Validated}>
                Validated
              </DropdownMenuRadioItem>
            </DropdownMenuRadioGroup>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              onClick={() => setDeleteMode(true)}
              className="w-full"
            >
              <Trash className="mr-2 h-4 w-4" />
              Delete
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
        <HypothesesDeleteAlert
          hypotheses={props.hypotheses}
          onDeletion={() => {
            setOptimisticIsDeleted(true)
            setDeleteMode(false)
          }}
          onMarkRefuted={() => {
            updateStatus(HypothesesState.Refuted)
            setDeleteMode(false)
          }}
          open={deleteMode}
          setOpen={setDeleteMode}
        />
      </li>
    </>
  )
}
