import { useRevalidator } from 'react-router-dom'
import { getHypothesesService } from './hypothesesService'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog'
import { useUserStore } from '@/stores/user'
import { Hypotheses, HypothesesState } from './typedef'
import { useTailwindScreenSize } from '@/hooks/useTailwindScreenSize'
import {
  Drawer,
  DrawerContent,
  DrawerContentWrapper,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
} from '@/components/ui/drawer'
import { Button } from '@/components/ui/button'

export type HypothesesProps = {
  hypotheses: Hypotheses
}
export function HypothesesDeleteAlert(props: {
  hypotheses: Hypotheses
  onDeletion: () => void
  onMarkRefuted: () => void
  open: boolean
  setOpen: (o: boolean) => void
}) {
  const isMediumScreen = useTailwindScreenSize('md')

  const isRefuted = props.hypotheses.status === HypothesesState.Refuted

  const user = useUserStore((state) => state.user)
  const revalidator = useRevalidator()

  async function onDelete() {
    props.onDeletion()

    const { success } = await getHypothesesService(!user).deleteHypotheses(
      props.hypotheses.hypotheses_id,
    )

    if (success) {
      revalidator.revalidate()
    }
  }

  if (!isMediumScreen) {
    return (
      <Drawer open={props.open}>
        <DrawerContent>
          <DrawerContentWrapper>
            <DrawerHeader>
              <DrawerTitle>Are you sure?</DrawerTitle>
              <DrawerDescription>
                This will completely remove the hypotheses from your history.
                {!isRefuted && (
                  <> Would you rather set its status to "refuted"?</>
                )}
              </DrawerDescription>
            </DrawerHeader>
            <div className="mt-4 space-y-4">
              {!isRefuted && (
                <Button
                  onClick={props.onMarkRefuted}
                  variant={'default'}
                  className="w-full"
                >
                  Mark as "Refuted"
                </Button>
              )}
              <Button
                onClick={props.onDeletion}
                variant={'destructive'}
                className="w-full"
              >
                Delete
              </Button>
              <Button
                onClick={() => props.setOpen(false)}
                variant={'outline'}
                className="w-full"
              >
                Cancel
              </Button>
            </div>
          </DrawerContentWrapper>
        </DrawerContent>
      </Drawer>
    )
  }

  return (
    <AlertDialog
      open={props.open}
      onOpenChange={() => {
        setTimeout(() => {
          // hack, shadcn is buggy
          document.body.style.pointerEvents = ''
        }, 500)
      }}
    >
      <AlertDialogContent>
        <AlertDialogTitle>Are you sure?</AlertDialogTitle>
        <AlertDialogDescription>
          This will completely remove the hypotheses from your history.
          {!isRefuted && <> Would you rather set its status to "refuted"?</>}
        </AlertDialogDescription>
        <AlertDialogFooter>
          {!isRefuted && (
            <AlertDialogAction onClick={props.onMarkRefuted}>
              Mark as "Refuted"
            </AlertDialogAction>
          )}
          <AlertDialogAction onClick={onDelete}>Delete</AlertDialogAction>
          <AlertDialogCancel onClick={() => props.setOpen(false)}>
            Cancel
          </AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
