import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog'
import { useState } from 'react'
import { LoginForm } from './login-form'

export function SignupButton() {
  const [open, setOpen] = useState(false)

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant={'primary'}>Sign Up</Button>
      </DialogTrigger>
      <DialogContent>
        <LoginForm intent="signup" onSubmit={() => setOpen(false)} />
      </DialogContent>
    </Dialog>
  )
}
