import { supabase } from '@/supabase'
import { toast } from 'sonner'
import { Hypotheses, HypothesesState } from './typedef'

export const onlineHypothesesService = {
  async fetchForCanvas(
    canvasId: string,
  ): Promise<{ success: boolean; hypotheses: Hypotheses[] }> {
    const { data: hypotheses, error } = await supabase
      .from('hypotheses')
      .select('*')
      .eq('canvas_id', canvasId)

    if (error) {
      toast.error(`Couldn't load hypotheses: ${error.message}`)
      return {
        success: false,
        hypotheses: [],
      }
    }

    return {
      hypotheses,
      success: true,
    }
  },

  async deleteHypotheses(hypothesesId: string): Promise<{ success: boolean }> {
    const { error } = await supabase
      .from('hypotheses')
      .delete()
      .eq('hypotheses_id', hypothesesId)

    if (error) {
      toast.error(error.message)
      return {
        success: false,
      }
    }

    return {
      success: true,
    }
  },

  async minorUpdate(
    hypothesesId: string,
    updates: Pick<Hypotheses, 'content' | 'status'>,
  ): Promise<{ success: boolean }> {
    const { error } = await supabase
      .from('hypotheses')
      .update({
        content: updates.content,
        status: updates.status,
        updated_at: new Date().toISOString(),
      })
      .eq('hypotheses_id', hypothesesId)

    if (error) {
      toast.error(error.message)
      return {
        success: false,
      }
    }

    return {
      success: true,
    }
  },

  /**
   * @param fakeCreatedAt Used to fake the createdAt date of a hypotheses. Important if you want to "clone" it.
   */
  async add(
    hypotheses: Pick<
      Hypotheses,
      'content' | 'bmc_column' | 'canvas_id' | 'user_id'
    >,
    fakeCreatedAt?: Date,
  ): Promise<{ success: boolean }> {
    const { error } = await supabase.from('hypotheses').insert({
      ...hypotheses,
      status: HypothesesState.ToValidate,
      created_at: fakeCreatedAt?.toISOString(),
    })

    if (error) {
      toast.error(error.message)
      return {
        success: false,
      }
    }

    return {
      success: true,
    }
  },

  async majorUpdate(
    hypothesesId: string,
    hypotheses: Pick<Hypotheses, 'content'>,
  ): Promise<{ success: boolean }> {
    const { error, data } = await supabase
      .from('hypotheses')
      .update({
        status: HypothesesState.Refuted,
        updated_at: new Date().toISOString(),
      })
      .eq('hypotheses_id', hypothesesId)
      .select()
      .single()

    if (error) {
      toast.error(error.message)
      return {
        success: false,
      }
    }

    const { canvas_id, user_id, bmc_column, created_at } = data

    return this.add(
      {
        bmc_column,
        canvas_id,
        user_id,
        content: hypotheses.content,
      },
      new Date(created_at),
    )
  },
}
