import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { Button } from '@/components/ui/button'
import { useUserStore } from '@/stores/user'
import { ShieldAlert } from 'lucide-react'
import { useNavigate, useRouteError } from 'react-router-dom'

export function ErrorElement() {
  const user = useUserStore((state) => state.user)
  const navigate = useNavigate()

  const error = useRouteError() as {
    status: number
    statusText: string
    data: string
    internal: boolean
  }

  const notFound = error.status === 404
  const showCode = error.data && error.status >= 500

  if (!user && notFound) {
    error.statusText += ` Are you logged in?`
  }

  return (
    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="space-y-8">
        <h1 className="text-2xl font-semibold text-slate-900">
          {notFound ? 'Not Found' : 'An Error Occurred'}
        </h1>
        <Alert>
          <ShieldAlert className="mr-2 h-4 w-4" />
          <AlertTitle>{error.status}</AlertTitle>
          <AlertDescription>{error.statusText}</AlertDescription>
        </Alert>
        {showCode && (
          <div>
            <p className="mb-0.5 ml-1 text-sm uppercase tracking-wider text-slate-600">
              Developer Code
            </p>
            <div className="rounded-md bg-slate-100 px-2 py-1">
              <p className="text-sm text-red-700">{error.data}</p>
            </div>
          </div>
        )}
        <div className="flex items-center justify-end gap-x-6">
          <Button onClick={() => navigate('/')}>Go back home</Button>
        </div>
      </div>
    </main>
  )
}
