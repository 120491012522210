import { Button } from '@/components/ui/button'
import { useEffect, useRef, useState } from 'react'
import TextAreaAutosize from 'react-textarea-autosize'
import { useClickAway } from '@uidotdev/usehooks'
import { useTailwindScreenSize } from '@/hooks/useTailwindScreenSize'

type HypothesesInputProps = {
  handleClose: () => void
  onSubmit: (
    event: React.FormEvent<HTMLFormElement>,
    content: string,
  ) => Promise<{ success: boolean }>
  onNext: () => void
  keepOpen: boolean
  defaultContent?: string
}
export function HypothesesInput(props: HypothesesInputProps) {
  const [content, setContent] = useState(() => props.defaultContent || '')
  const isLargeScreen = useTailwindScreenSize('lg')

  const textareaRef = useRef<HTMLTextAreaElement | null>(null)
  useEffect(() => {
    textareaRef.current?.focus()
    textareaRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: isLargeScreen ? 'nearest' : 'center',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const submitButton = useRef<HTMLButtonElement | null>(null)

  async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    const tempContent = content

    if (!tempContent) {
      onCancel()
      return
    }

    setContent('')

    const { success } = await props.onSubmit(e, content)
    if (success && props.keepOpen) {
      textareaRef.current?.focus()
    } else if (success && !props.keepOpen) {
      props.handleClose?.()
    } else {
      setContent(tempContent)
    }
  }

  function onCancel() {
    setContent('')
    props.handleClose?.()
  }

  const formRef = useClickAway<HTMLFormElement>((e) => {
    if (e.type === 'mousedown') {
      onCancel()
    }
  })

  return (
    <form
      ref={formRef}
      onMouseDown={(e) => e.stopPropagation()}
      onTouchStart={(e) => e.stopPropagation()}
      onSubmit={onSubmit}
      className="w-full"
    >
      <TextAreaAutosize
        ref={textareaRef}
        value={content}
        name="content"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()
            submitButton.current?.click()
          }
          if (e.key === 'Escape') {
            e.preventDefault()
            onCancel()
          }
          if (e.key === 'Tab') {
            e.preventDefault()
            props.onNext()
          }
        }}
        onChange={(e) => {
          setContent(e.target.value)
        }}
        className="w-full resize-none appearance-none rounded-md bg-slate-100 px-3 py-3 text-slate-700 outline-none"
      />
      <div className="mt-2 flex justify-end space-x-3 lg:space-x-2">
        <Button
          ref={submitButton}
          type="submit"
          size={'sm'}
          variant={'ghost'}
          className="text-slate-600/90"
        >
          Done
        </Button>
        <Button
          className="text-slate-600/90"
          type="button"
          onClick={onCancel}
          size={'sm'}
          variant={'ghost'}
        >
          Cancel
        </Button>
      </div>
    </form>
  )
}
