import { columnSettingsService } from '@/hypotheses/columnSettings'
import { supabase } from '@/supabase'
import { toast } from 'sonner'
import { Canvas } from './typedef'

export const onlineCanvasService = {
  async create(
    title: string,
    description: string,
  ): Promise<
    { success: true; canvas: Canvas } | { success: false; canvas: null }
  > {
    const { data: sessionData, error: sessionError } =
      await supabase.auth.getSession()

    if (sessionError || !sessionData.session) {
      toast.error(
        sessionError
          ? `You are not logged in: ${sessionError.message}`
          : `You are not logged in.`,
      )
      return {
        success: false,
        canvas: null,
      }
    }

    const { error, data: canvas } = await supabase
      .from('canvases')
      .upsert({
        owner_id: sessionData.session.user.id,
        title,
        description,
      })
      .select()
      .single()

    if (error) {
      toast.error(`Something went wrong on our end. Excuses.`)
      return {
        success: false,
        canvas: null,
      }
    }

    return {
      success: true,
      canvas,
    }
  },

  async updateCanvas(
    canvasId: string,
    diff: Pick<Canvas, 'description' | 'title'>,
  ): Promise<{ success: boolean }> {
    const { error } = await supabase
      .from('canvases')
      .update({
        description: diff.description,
        title: diff.title,
        updated_at: new Date().toISOString(),
      })
      .eq('canvas_id', canvasId)

    if (error) {
      toast.error(`Deletion failed: ${error.message}`)
      return {
        success: false,
      }
    }

    return {
      success: true,
    }
  },

  async deleteCanvas(canvasId: string): Promise<{ success: boolean }> {
    const { error } = await supabase
      .from('canvases')
      .delete()
      .eq('canvas_id', canvasId)

    if (error) {
      toast.error(`Deletion failed: ${error.message}`)
      return {
        success: false,
      }
    }

    await columnSettingsService.deleteAllFilters(canvasId)

    return {
      success: true,
    }
  },

  async fetchAll(): Promise<{ canvases: Canvas[]; success: boolean }> {
    const { data: canvases, error } = await supabase
      .from('canvases')
      .select('*')

    if (error) {
      toast.error(`Couldn't load the canvases: ${error.message}`)
      return {
        success: false,
        canvases: [],
      }
    }

    return {
      canvases,
      success: true,
    }
  },

  async fetchSingle(
    canvasId: string,
  ): Promise<{ success: boolean; canvas: Canvas | null }> {
    const { data, error } = await supabase
      .from('canvases')
      .select('*')
      .eq('canvas_id', canvasId)
      .maybeSingle()

    if (error) {
      toast.error(`Couldn't load the canvas: ${error.message}`)
      return {
        success: false,
        canvas: null,
      }
    }

    if (!data) {
      toast.error(`Canvas not found.`)
      return {
        success: true,
        canvas: null,
      }
    }

    return {
      success: true,
      canvas: data,
    }
  },
}
