import { cn } from '@/lib/utils'
import { Ban, HelpCircle, CheckCheck, Dot } from 'lucide-react'
import { HypothesesToRender, HypothesesState } from './typedef'

type HypothesesItemDisplayProps = {
  hypotheses: HypothesesToRender
}
export function HypothesesDisplay(props: HypothesesItemDisplayProps) {
  const Icon = () => {
    switch (props.hypotheses.status) {
      case HypothesesState.Refuted:
        return <Ban className="h-4 w-4 text-rose-400/80" />
      case HypothesesState.ToValidate:
        return <HelpCircle className="h-4 w-4 text-blue-500/70" />
      case HypothesesState.Validated:
        return <CheckCheck className="h-4 w-4 text-emerald-600" />
      default:
        return <Dot className="text-black/50" />
    }
  }

  return (
    <div className="relative flex w-full justify-start rounded-md px-2 py-2 text-left text-slate-700/90 hover:bg-slate-100/80">
      <div className="mr-2 flex-grow-0 pt-1">
        <div className="flex h-4 w-4 items-center justify-center">
          <Icon />
        </div>
      </div>
      <p
        className={cn('break-words', {
          'line-through': props.hypotheses.status === HypothesesState.Refuted,
          'underline underline-offset-2':
            props.hypotheses.status === HypothesesState.Validated,
        })}
      >
        {props.hypotheses.content}
      </p>{' '}
    </div>
  )
}
