import { useMediaQuery } from '@uidotdev/usehooks'

type Size = 'sm' | 'md' | 'lg' | 'xl' | '<sm' | '2xl'

const mediaQueries: Record<Size, string> = {
  '<sm': '(max-width: 639px)',
  sm: '(min-width: 640px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 1024px)',
  xl: '(min-width: 1280px)',
  '2xl': '(min-width: 1536px)',
}

export function useTailwindScreenSize(size: Size) {
  return useMediaQuery(mediaQueries[size])
}
