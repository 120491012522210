import { Outlet, useRevalidator } from 'react-router-dom'
import { NavBar } from './nav/nav'
import { Toaster } from 'sonner'
import { supabase } from './supabase'
import { useUserStore } from './stores/user'
import { Suspense, useEffect } from 'react'
import { userService } from './users/service'
import { getCanvasService } from './canvases/canvasService'
import { getHypothesesService } from './hypotheses/hypothesesService'
import { deleteLocalCanvas } from './canvases/canvasService.local'
import { Session } from '@supabase/supabase-js'
import { useSingletonStore } from './stores/singletons'
import { Canvas } from './canvases/typedef'
import { Hypotheses } from './hypotheses/typedef'
import { Feedback } from './feedback/feedback-form'
import { Loader2 } from 'lucide-react'

export function Root() {
  const setUser = useUserStore((state) => state.setUser)
  const setOpenSingletonInput = useSingletonStore(
    (state) => state.setOpenSingletonInput,
  )
  const revalidator = useRevalidator()

  useEffect(() => {
    userService
      .getSession()
      .then((s) => s.success && setUser(s.session?.user || null))

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      switch (event) {
        case 'USER_UPDATED':
        case 'SIGNED_IN':
        case 'INITIAL_SESSION':
          setUser(session?.user || null)
          break
        case 'SIGNED_OUT':
          setOpenSingletonInput('')
          setUser(null)
          break
      }

      revalidator.revalidate()
    })

    return () => {
      subscription.unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="flex w-full flex-col items-center justify-start bg-white lg:h-screen lg:max-h-screen">
        <div className="w-full lg:flex-grow-0">
          <NavBar />
        </div>
        <div className="w-full overflow-hidden lg:!flex-grow">
          <Suspense fallback={<LoadingAnimation />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
      <Toaster richColors position="bottom-center" />
      <Feedback />
    </>
  )
}

function LoadingAnimation() {
  return (
    <main className="flex h-full w-full items-center justify-center bg-white">
      <Loader2 className="h-12 w-12 animate-spin text-slate-700" />
    </main>
  )
}

Root.loader = async function rootLoader() {
  const { success: sessionSuccess, session } = await userService.getSession()

  if (!sessionSuccess) {
    throw new Response('ROOT_LOADER_SESSION_NOT_LOADABLE', {
      status: 500,
      statusText: "We couldn't load any user data.",
    })
  }

  const { canvases, success: canvasSuccess } =
    await getCanvasService(!session).fetchAll()

  if (!canvasSuccess) {
    throw new Response('ROOT_LOADER_CANVAS_NOT_LOADABLE', {
      status: 500,
      statusText: "We couldn't load any canvas data.",
    })
  }

  if (session?.user && !session.user.user_metadata.migrated_local_hypotheses) {
    await onInitialSignup(session, canvases[0])
  }

  /**
   * A user should always have a canvas.
   * Either it is created in IndexedDB when first opening the page,
   * or it is created on signup.
   * If he deletes his last canvas, we add a new one at the DB level.
   */
  return {
    canvases,
  }
}

async function onInitialSignup(
  session: Session,
  canvas: Canvas,
): Promise<void> {
  const { hypotheses } = await getHypothesesService(
    true /* local */,
  ).fetchForCanvas('local')

  const mapped: Omit<Hypotheses, 'hypotheses_id'>[] = hypotheses.map((h) => ({
    user_id: session.user.id,
    content: h.content,
    bmc_column: h.bmc_column,
    created_at: h.created_at,
    status: h.status,
    updated_at: h.updated_at,
    canvas_id: canvas.canvas_id,
  }))

  const { error } = await supabase.from('hypotheses').insert(mapped)

  if (error) {
    throw new Response('ROOT_LOADER_CANT_COPY_HYPOTHESES', {
      status: 500,
      statusText: "We couldn't copy your logged out data.",
    })
  }

  const { error: updateError } = await supabase.auth.updateUser({
    data: {
      migrated_local_hypotheses: true,
    },
  })

  if (updateError) {
    throw new Response('ROOT_LOADER_CANT_PREVENT_LOCAL_SAVING', {
      status: 500,
      statusText: "We couldn't copy your logged out data properly.",
    })
  }

  await deleteLocalCanvas()
}
