import { createClient } from '@supabase/supabase-js';
import z from 'zod';
import { fromZodError } from 'zod-validation-error';
import { Database } from './types';

const schema = z.object({
  url: z.string(),
  key: z.string(),
});

const result = schema.safeParse({
  url: import.meta.env.VITE_SUPABASE_URL,
  key: import.meta.env.VITE_SUPABASE_ANON_KEY,
});

if (!result.success) {
  const validationError = fromZodError(result.error);
  throw validationError;
}

export const supabase = createClient<Database>(
  result.data.url,
  result.data.key
);
