import { Tables } from '@/supabase/types'

export enum HypothesesState {
  Refuted = 'refuted',
  ToValidate = 'to_validate',
  Validated = 'validated',
}

export type Hypotheses = Tables<'hypotheses'>
export type HypothesesToRender = Pick<
  Hypotheses,
  'content' | 'status' | 'hypotheses_id'
>

export type BMCKey = Tables<'hypotheses'>['bmc_column']
