import { BMCKey } from '@/hypotheses/typedef'
import { create } from 'zustand'

interface SingletonStore {
  openSingletonInput: string
  setOpenSingletonInput: (s: string) => void
  nextHypothesesInput: (k: BMCKey) => void
  openSingletonModal: string
  setOpenSingletonModal: (s: string) => void
}

export const useSingletonStore = create<SingletonStore>()(function (set) {
  return {
    openSingletonInput: '',
    setOpenSingletonInput: (s: string) =>
      set(() => ({ openSingletonInput: s })),
    openSingletonModal: '',
    setOpenSingletonModal: (s) => set(() => ({ openSingletonModal: s })),
    nextHypothesesInput: (key) => {
      return set((state) => {
        if (!state.openSingletonInput) {
          return state
        }

        return {
          ...state,
          openSingletonInput: nextId(key),
        }
      })
    },
  }
})

export const SINGLETON_MODALS = Object.freeze({
  FEEDBACK: `feedback_modal`,
})

function nextId(bmcKey: BMCKey): string {
  const idx = HYPOTHESES_INPUT_IDS.findIndex((v) => !!v[bmcKey])

  const nextIdx = (idx + 1) % 9

  return Object.values(HYPOTHESES_INPUT_IDS[nextIdx])[0]
}

export const HYPOTHESES_INPUT_IDS = [
  { key_partners: 'add_hypotheses_key_partners' },
  { key_activities: 'add_hypotheses_key_activities' },
  { key_resources: 'add_hypotheses_key_resources' },
  { value_propositions: 'add_hypotheses_value_propositions' },
  { customer_relationships: 'add_hypotheses_customer_relationships' },
  { channels: 'add_hypotheses_channels' },
  { customer_segments: 'add_hypotheses_customer_segments' },
  { cost_structure: 'add_hypotheses_cost_structure' },
  { revenue_streams: 'add_hypotheses_revenue_streams' },
]
