import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog'
import { useState } from 'react'
import { LoginForm } from './login-form'
import { LogIn } from 'lucide-react'

export function LoginButton() {
  const [open, setOpen] = useState(false)

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant={'ghost'} className="text-white">
          <LogIn className="mr-2 h-4 w-4 text-slate-400" />
          Log In
        </Button>
      </DialogTrigger>
      <DialogContent>
        <LoginForm intent="signin" onSubmit={() => setOpen(false)} />
      </DialogContent>
    </Dialog>
  )
}
