import { useUserStore } from '@/stores/user'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { userService } from './service'

type LoginButtonProps = {
  children: (loading: boolean, logout: () => Promise<void>) => React.ReactNode
}
export function LogoutButton(props: LoginButtonProps) {
  const user = useUserStore((state) => state.user)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  if (!user) {
    return null
  }

  async function logout() {
    setIsLoading(true)
    const { error } = await userService.logout()
    if (!error) {
      navigate('/', {
        replace: true,
      })
    }
    setIsLoading(false)
  }

  return <>{props.children(isLoading, logout)}</>
}
