import { useState } from 'react'
import { Check, ChevronsUpDown } from 'lucide-react'

import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from '@/components/ui/command'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { useNavigate } from 'react-router-dom'
import { useSelectedCanvas } from './useSelectedCanvas'
import { CreateCanvasDrawer, CreateCanvasModal } from './canvas-create-modal'
import { useUserStore } from '@/stores/user'

export function CanvasSelector() {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()

  const user = useUserStore((state) => state.user)
  const { selectedCanvas, availableCanvases } = useSelectedCanvas()

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full justify-between overflow-hidden bg-white text-sm md:text-sm lg:w-[300px]"
        >
          <span className="truncate  text-slate-800">
            {selectedCanvas?.title || 'Please select a Canvas'}
          </span>
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent align="start" className="w-[300px] p-0">
        <Command className="w-full">
          <CommandInput placeholder="Search canvas..." />
          <CommandEmpty>No Canvas found.</CommandEmpty>
          <CommandGroup className="w-full">
            {availableCanvases.map((canvas) => (
              <CommandItem
                key={canvas.canvas_id}
                value={canvas.title}
                onSelect={() => {
                  navigate(`/bmc/${canvas.canvas_id}`)
                  setOpen(false)
                }}
                className="overflow-hidden"
              >
                <Check
                  className={cn(
                    'mr-2 h-4 w-4 flex-shrink-0',
                    selectedCanvas?.canvas_id === canvas.canvas_id
                      ? 'opacity-100'
                      : 'opacity-0',
                  )}
                />
                <span className="truncate">{canvas.title}</span>
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
        {user ? (
          <>
            <div className="mt-8 hidden md:block">
              <CreateCanvasModal />
            </div>
            <div className="mt-8 md:hidden">
              <CreateCanvasDrawer />
            </div>
          </>
        ) : (
          <div className="border-t-2 border-slate-200 px-4 py-2 text-sm">
            Not logged in.
            <br />
            Please log in to create more canvases.
          </div>
        )}
      </PopoverContent>
    </Popover>
  )
}
