import { Button } from '@/components/ui/button'
import {
  FormField,
  FormItem,
  FormControl,
  FormMessage,
  Form as FormUI,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { zodResolver } from '@hookform/resolvers/zod'
import { Loader2 } from 'lucide-react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'
import { userService } from './service'

const formSchema = z.object({
  email: z.string().min(1).email(),
  password: z.string().min(8),
})

type LoginFormProps = {
  onSubmit: () => void
  intent: 'signup' | 'signin'
}
export function LoginForm(props: LoginFormProps) {
  const [intent, setIntent] = useState<'signup' | 'signin'>(() => props.intent)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  })

  async function onSubmit(values: z.infer<typeof formSchema>) {
    setIsLoading(true)

    const { user } =
      intent === 'signin'
        ? await userService.signIn(values.email, values.password)
        : await userService.signUp(values.email, values.password)

    if (user) {
      props.onSubmit()
      navigate('/', {
        replace: true,
      })
    }

    setIsLoading(false)
  }

  return (
    <div className="mt-4 w-full max-w-full space-y-10">
      <h4 className="text-center text-xl font-semibold text-slate-700">
        {intent === 'signin' ? 'Log In' : 'Sign Up'}
      </h4>
      <FormUI {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">
          <div className="relative space-y-2 rounded-md shadow-sm">
            <FormField
              name="email"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input placeholder="mike@example.com" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="password"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input type="password" placeholder="********" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="flex items-center justify-end">
            <div className="text-sm leading-6">
              <Button
                variant={'link'}
                className="font-semibold text-slate-600 hover:text-slate-500"
              >
                Forgot password?
              </Button>
            </div>
          </div>

          <div>
            <Button disabled={isLoading} type="submit" className="w-full">
              {isLoading ? (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              ) : intent === 'signin' ? (
                'Sign in'
              ) : (
                'Sign up'
              )}
            </Button>
          </div>
        </form>

        <p className="text-center text-sm leading-6 text-slate-500">
          {intent === 'signup' ? 'Already have an account?' : 'No account?'}{' '}
          <Button
            className="px-0"
            onClick={() => setIntent(intent === 'signin' ? 'signup' : 'signin')}
            variant={'link'}
          >
            {intent === 'signin' ? 'Sign up' : 'Sign in'}
          </Button>
        </p>
      </FormUI>
    </div>
  )
}
