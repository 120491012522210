import { LoginButton } from '@/users/login-button'
import { UserDropdown } from '@/users/user-dropdown'
import { CanvasSelector } from '@/canvases/canvas-selector'
import { useUserStore } from '@/stores/user'
import { CanvasUpdateDrawer, CanvasUpdateModal } from '@/canvases'
import { User } from '@supabase/supabase-js'
import { Button } from '@/components/ui/button'
import {
  Loader,
  LogOut,
  MenuIcon,
  MessageCircleMore,
  Settings,
  Users,
} from 'lucide-react'
import {
  Drawer,
  DrawerContent,
  DrawerContentWrapper,
  DrawerTrigger,
} from '@/components/ui/drawer'
import { LoginForm } from '@/users/login-form'
import { useState } from 'react'
import { LogoutButton } from '@/users/logout-button'
import { Badge } from '@/components/ui/badge'
import { OpenFeedbackButton } from '@/feedback/open-feedback-button'
import { SignupButton } from '@/users/signup-button'

export function NavBar() {
  const user = useUserStore((s) => s.user)

  return (
    <>
      <MobileNav user={user} />
      <DesktopNav user={user} />
    </>
  )
}

function MobileNav(props: { user: User | null }) {
  const [open, setOpen] = useState(false)

  const { user } = props

  return (
    <div className="flex w-full items-center justify-between bg-slate-800 px-5 py-4 md:hidden">
      <OpenFeedbackButton>
        {(openFeedback) => (
          <Button
            variant={'ghost'}
            onClick={openFeedback}
            className="text-base text-white"
          >
            <MessageCircleMore className="mr-2 h-5 w-5 text-slate-400" />
            Feedback
          </Button>
        )}
      </OpenFeedbackButton>
      <Drawer open={open} onOpenChange={setOpen}>
        <DrawerTrigger asChild>
          {user ? (
            <Button variant={'ghost'} className="text-white">
              <MenuIcon />
            </Button>
          ) : (
            <Button variant={'primary'} className="mr-0.5 text-base">
              Sign Up
            </Button>
          )}
        </DrawerTrigger>
        <DrawerContent>
          <DrawerContentWrapper>
            {user ? (
              <div className="w-full">
                <small className="text-sm font-medium">Select Canvas</small>
                <div className="mt-2 flex w-full items-center space-x-2">
                  <CanvasSelector />
                  <CanvasUpdateDrawer />
                </div>
              </div>
            ) : (
              <LoginForm intent="signup" onSubmit={() => setOpen(false)} />
            )}
            <div className="mt-12 space-y-4">
              {user && (
                <>
                  <Button
                    variant={'outline'}
                    className="pointer-events-none relative w-full"
                  >
                    <Badge className="absolute right-5 z-10 text-xs">
                      Soon
                    </Badge>
                    <span className="absolute -inset-0 bg-white/50"></span>
                    <Settings className="mr-2 h-5 w-5" />
                    Update Profile
                  </Button>
                  <Button
                    variant={'outline'}
                    className="pointer-events-none relative w-full"
                  >
                    <Badge className="absolute right-5 z-10 text-xs">
                      Soon
                    </Badge>
                    <span className="absolute -inset-0 bg-white/50"></span>
                    <Users className="mr-2 h-5 w-5" />
                    Invite Users
                  </Button>
                </>
              )}
              <LogoutButton>
                {(loading, logout) => (
                  <Button
                    variant={'outline'}
                    onClick={logout}
                    className="w-full"
                  >
                    {loading ? (
                      <Loader className="mr-2 h-4 w-4 animate-spin" />
                    ) : (
                      <LogOut className="mr-2 h-4 w-4" />
                    )}{' '}
                    Log Out
                  </Button>
                )}
              </LogoutButton>
            </div>
          </DrawerContentWrapper>
        </DrawerContent>
      </Drawer>
    </div>
  )
}

function DesktopNav(props: { user: User | null }) {
  const { user } = props

  return (
    <div className="hidden items-center justify-between bg-slate-700 shadow md:flex md:py-6 md:pl-5 md:pr-5 lg:py-2.5 lg:pl-4 lg:pr-3">
      <div className="flex items-center space-x-2">
        <CanvasSelector />
        {user && <CanvasUpdateModal />}
      </div>
      {user ? (
        <UserDropdown />
      ) : (
        <div className="flex items-center space-x-2">
          <OpenFeedbackButton>
            {(openFeedback) => (
              <Button
                variant={'ghost'}
                onClick={openFeedback}
                className="text-white"
              >
                <MessageCircleMore className="mr-2 h-4 w-4 text-slate-400" />
                Feedback
              </Button>
            )}
          </OpenFeedbackButton>
          <LoginButton />

          <div className="pl-6">
            <SignupButton />
          </div>
        </div>
      )}
    </div>
  )
}
