import { supabase } from '@/supabase'
import { Session, User } from '@supabase/supabase-js'
import { toast } from 'sonner'

async function getSession(): Promise<{
  success: boolean
  session: Session | null
}> {
  const { data, error } = await supabase.auth.getSession()

  if (error) {
    toast.error(`Couldn't load user data: ${error.message}`)
    return {
      success: false,
      session: null,
    }
  }

  return {
    success: true,
    session: data.session,
  }
}

async function logout() {
  return await supabase.auth.signOut()
}

async function signUp(
  email: string,
  password: string,
): Promise<{ user: User | null }> {
  const { data, error } = await supabase.auth.signUp({
    email,
    password,
  })

  if (error) {
    toast.error(`${error.message}`)
    return {
      user: null,
    }
  }

  if (!data.user) {
    toast.error(`That didn't work — something's wrong on our end.`)
    return {
      user: null,
    }
  }

  toast.success(`Welcome, ${data.user.email}!`)

  return {
    user: data.user,
  }
}

async function signIn(
  email: string,
  password: string,
): Promise<{ user: User | null }> {
  const { data, error } = await supabase.auth.signInWithPassword({
    email,
    password,
  })

  if (error) {
    toast.error(`${error.message}`)
    return {
      user: null,
    }
  }

  toast.success(`Welcome back, ${data.user.email}!`)

  return {
    user: data.user,
  }
}

export const userService = {
  getSession,
  signIn,
  signUp,
  logout,
}
