import { redirect, useLoaderData } from 'react-router-dom'
import { BMCContent } from './bmc-content'
import { BMCData } from './typedef'
import { cn } from '@/lib/utils'
import { getCanvasService } from '@/canvases/canvasService'
import { getHypothesesService } from '../hypotheses/hypothesesService'
import { userService } from '@/users/service'
import { BMCKey, Hypotheses } from '@/hypotheses/typedef'
import { OpenFeedbackButton } from '@/feedback/open-feedback-button'
import { Button } from '@/components/ui/button'
import { MessageCircleMore } from 'lucide-react'
import { useUserStore } from '@/stores/user'
import { User } from '@supabase/supabase-js'
import {
  LS_DEMO_CANVAS_KEY,
  LS_LOCAL_CANVAS_KEY,
} from '@/canvases/canvasService.local'

export function BmcPage() {
  const { bmcData } = useLoaderData() as {
    bmcData: BMCData
  }
  const user = useUserStore((state) => state.user)

  return (
    <>
      <BmcPageMobile bmcData={bmcData} user={user} />
      <BmcPageDesktop bmcData={bmcData} user={user} />
    </>
  )
}

function BmcPageMobile(props: { bmcData: BMCData; user: User | null }) {
  const { bmcData, user } = props

  return (
    <>
      <div className="divide-y px-2 pb-20 lg:hidden">
        <BMCContent
          fieldTitle={'key_partners'}
          hypotheses={bmcData?.hypotheses}
        />
        <BMCContent
          fieldTitle={'key_activities'}
          hypotheses={bmcData?.hypotheses}
        />
        <BMCContent
          fieldTitle={'key_resources'}
          hypotheses={bmcData?.hypotheses}
        />
        <BMCContent
          fieldTitle={'value_propositions'}
          hypotheses={bmcData?.hypotheses}
        />
        <BMCContent
          fieldTitle={'customer_relationships'}
          hypotheses={bmcData?.hypotheses}
        />
        <BMCContent fieldTitle={'channels'} hypotheses={bmcData?.hypotheses} />
        <BMCContent
          fieldTitle={'customer_segments'}
          hypotheses={bmcData?.hypotheses}
        />
        <BMCContent
          fieldTitle={'cost_structure'}
          hypotheses={bmcData?.hypotheses}
        />
        <BMCContent
          fieldTitle={'revenue_streams'}
          hypotheses={bmcData?.hypotheses}
        />
      </div>

      {user && (
        <div className="group fixed bottom-8 right-8 hidden border-0 md:block">
          <OpenFeedbackButton>
            {(openFeedback) => (
              <Button
                variant={'outline'}
                className="flex items-center justify-center rounded-full px-3 py-6 text-slate-600 shadow"
                onClick={openFeedback}
              >
                <MessageCircleMore className="h-6 w-6 group-hover:mr-2" />
                <span className="hidden text-base group-hover:inline">
                  Feedback
                </span>
              </Button>
            )}
          </OpenFeedbackButton>
        </div>
      )}
    </>
  )
}

const gridStyle = {
  gridTemplateRows: '31% 31% 38%',
  gridTemplateColumns: 'repeat(10, minmax(0, 1fr))',
}

const gridLayout = {
  columnOne: 'col-start-1 col-span-2 row-start-1 row-span-2 pl-1',
  columnTwoTop: 'col-start-3 col-span-2 row-start-1 row-span-1',
  columnTwoBottom: 'col-start-3 col-span-2 row-start-2 row-span-1',
  columnThree: 'col-start-5 col-span-2 row-start-1 row-span-2',
  columnFourTop: 'col-start-7 col-span-2 row-start-1 row-span-1',
  columnFourBottom: 'col-start-7 col-span-2 row-start-2 row-span-1',
  columnFive: 'col-start-9 col-span-2 row-start-1 row-span-2 pr-1',
  belowOne: 'col-start-1 col-span-5 row-start-3 row-span-1 pl-1',
  belowTwo: 'col-start-6 col-span-5 row-start-3 row-span-1 pr-1',
}

function BmcPageDesktop(props: { bmcData: BMCData; user: User | null }) {
  const { bmcData, user } = props

  return (
    <div
      style={gridStyle}
      className="relative hidden h-full max-h-full min-h-0 w-full min-w-0 overflow-hidden border-t bg-white shadow lg:grid"
    >
      <BMCContent
        className={cn('border-b lg:border-r', gridLayout['columnOne'])}
        fieldTitle={'key_partners'}
        hypotheses={bmcData?.hypotheses}
      />
      <BMCContent
        className={cn('border-b border-r', gridLayout['columnTwoTop'])}
        fieldTitle={'key_activities'}
        hypotheses={bmcData?.hypotheses}
      />
      <BMCContent
        className={cn('border-b border-r', gridLayout['columnTwoBottom'])}
        fieldTitle={'key_resources'}
        hypotheses={bmcData?.hypotheses}
      />
      <BMCContent
        className={cn('border-b border-r', gridLayout['columnThree'])}
        fieldTitle={'value_propositions'}
        hypotheses={bmcData?.hypotheses}
      />
      <BMCContent
        className={cn('border-b border-r', gridLayout['columnFourTop'])}
        fieldTitle={'customer_relationships'}
        hypotheses={bmcData?.hypotheses}
      />
      <BMCContent
        className={cn(
          'row-start-5 border-b border-r',
          gridLayout['columnFourBottom'],
        )}
        fieldTitle={'channels'}
        hypotheses={bmcData?.hypotheses}
      />
      <BMCContent
        className={cn('border-b border-r', gridLayout['columnFive'])}
        fieldTitle={'customer_segments'}
        hypotheses={bmcData?.hypotheses}
      />
      <BMCContent
        className={cn('row-start-3 border-b border-r', gridLayout['belowOne'])}
        fieldTitle={'cost_structure'}
        hypotheses={bmcData?.hypotheses}
      />
      <BMCContent
        className={cn(' border-b border-r', gridLayout['belowTwo'])}
        fieldTitle={'revenue_streams'}
        hypotheses={bmcData?.hypotheses}
      />

      {user && (
        <div className="group fixed bottom-12 right-12">
          <OpenFeedbackButton>
            {(openFeedback) => (
              <Button
                variant={'outline'}
                className="flex items-center justify-center rounded-full px-3 py-6 text-slate-600 shadow"
                onClick={openFeedback}
              >
                <MessageCircleMore className="h-6 w-6 group-hover:mr-2" />
                <span className="hidden text-base group-hover:inline">
                  Feedback
                </span>
              </Button>
            )}
          </OpenFeedbackButton>
        </div>
      )}
    </div>
  )
}

BmcPage.loader = async function bmcPageLoader(args: {
  params: Readonly<Partial<{ canvasId: string }>>
}): Promise<{ bmcData: BMCData } | Response> {
  const { session, success } = await userService.getSession()

  if (!success) {
    throw new Response('BMC_LOADER_CANT_LOAD_SESSION', {
      status: 500,
      statusText: "We can't load your user data.",
    })
  }

  const isLocal = !session
  const canvasId = args.params.canvasId

  if (!canvasId) {
    return redirect('/')
  }

  if (
    isLocal &&
    ![LS_LOCAL_CANVAS_KEY, LS_DEMO_CANVAS_KEY].includes(canvasId)
  ) {
    return redirect('/')
  }

  if (
    !isLocal &&
    [LS_LOCAL_CANVAS_KEY, LS_DEMO_CANVAS_KEY].includes(canvasId)
  ) {
    return redirect('/')
  }

  const { success: canvasSuccess, canvas } =
    await getCanvasService(isLocal).fetchSingle(canvasId)

  if (canvasSuccess && !canvas) {
    throw new Response('', {
      status: 404,
      statusText: 'Business Model Canvas not found.',
    })
  }

  const { success: hypothesesSuccess, hypotheses } =
    await getHypothesesService(isLocal).fetchForCanvas(canvasId)

  if (!canvasSuccess || !hypothesesSuccess || !canvas) {
    throw new Response('BMC_LOADER_CANT_FETCH_HYPOTHESES', {
      status: 500,
      statusText: "We can't load your canvas data. Sorry!",
    })
  }

  const byKey: Record<BMCKey, Hypotheses[]> = {
    channels: [],
    cost_structure: [],
    customer_relationships: [],
    customer_segments: [],
    key_activities: [],
    key_partners: [],
    key_resources: [],
    revenue_streams: [],
    value_propositions: [],
  }

  for (const h of hypotheses) {
    byKey[h.bmc_column].push(h)
  }

  const bmcData = {
    ...canvas,
    hypotheses: byKey,
  }

  return {
    bmcData,
  }
}
