import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { useUserStore } from '@/stores/user'
import {
  Check,
  Loader,
  LogOut,
  MessageCircleMore,
  Settings,
  Users,
} from 'lucide-react'
import { LogoutButton } from './logout-button'
import { OpenFeedbackButton } from '@/feedback/open-feedback-button'

export function UserDropdown() {
  const user = useUserStore((state) => state.user)

  if (!user) {
    return null
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="px-2">
          <Check className="-mb-px mr-2 h-4 w-4 text-emerald-600" />
          <span className="font-semibold text-slate-200">{user.email}</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>My Account</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem className="pointer-events-none flex justify-between">
            <div className="relative flex items-center opacity-75">
              <div className="absolute -inset-0 bg-white/25"></div>
              <Settings className="mr-2 h-4 w-4" />
              <span>Update Profile</span>
            </div>
            <Badge>Soon</Badge>
          </DropdownMenuItem>
          <DropdownMenuItem className="pointer-events-none flex justify-between">
            <div className="relative flex items-center opacity-75">
              <div className="absolute -inset-0 bg-white/25"></div>
              <Users className="mr-2 h-4 w-4" />
              <span>Invite Users</span>
            </div>
            <Badge>Soon</Badge>
          </DropdownMenuItem>
          <OpenFeedbackButton>
            {(openFeedback) => (
              <DropdownMenuItem onClick={openFeedback}>
                <MessageCircleMore className="mr-2 h-4 w-4" />
                Feedback?
              </DropdownMenuItem>
            )}
          </OpenFeedbackButton>
          <LogoutButton>
            {(loading, logout) => (
              <DropdownMenuItem onClick={logout}>
                {loading ? (
                  <Loader className="mr-2 h-4 w-4 animate-spin" />
                ) : (
                  <LogOut className="mr-2 h-4 w-4" />
                )}{' '}
                <span>Log Out</span>
              </DropdownMenuItem>
            )}
          </LogoutButton>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
