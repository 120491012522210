import { FormEvent } from 'react'
import { useParams } from 'react-router-dom'
import { HypothesesInput } from '../hypotheses/hypotheses-input'
import { getHypothesesService } from '../hypotheses/hypothesesService'
import { useUserStore } from '@/stores/user'
import { useOptimistic } from '@/hooks/useOptimistic'
import { uuid } from '@/lib/utils'
import { BMCKey, Hypotheses, HypothesesState } from '@/hypotheses/typedef'

type BMCCreateButtonProps = {
  fieldTitle: BMCKey
  addHypotheses: (
    h: Pick<Hypotheses, 'hypotheses_id' | 'content' | 'status'>,
  ) => void
  handleCancel: () => void
  undoOptimistic: () => void
  onNext: () => void
}
export function BMCCreateInput(props: BMCCreateButtonProps) {
  const user = useUserStore((state) => state.user)
  const revalidator = useOptimistic(() => props.undoOptimistic())
  const params = useParams() as { canvasId: string }

  async function onSubmit(
    _event: FormEvent<HTMLFormElement>,
    content: string,
  ): Promise<{ success: boolean }> {
    if (!content) {
      onCancel()
      return { success: false }
    }

    console.log('starting submit')

    props.addHypotheses({
      hypotheses_id: uuid(),
      content,
      status: HypothesesState.ToValidate,
    })

    const toReturn = await getHypothesesService(!user).add({
      bmc_column: props.fieldTitle,
      canvas_id: params.canvasId,
      content: content,
      user_id: user?.id || 'local',
    })

    revalidator.revalidate()

    return toReturn
  }

  function onCancel() {
    props.handleCancel()
  }

  return (
    <HypothesesInput
      onSubmit={onSubmit}
      handleClose={props.handleCancel}
      keepOpen
      onNext={props.onNext}
    />
  )
}
