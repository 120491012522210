import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuCheckboxItem,
} from '@/components/ui/dropdown-menu'
import { HypothesesFilter } from '@/hypotheses/columnSettings'

type FilterDropdownProps = {
  filter: HypothesesFilter
  onFilterChange: (f: HypothesesFilter) => void
  children: (active: boolean) => React.ReactNode
}
export function FilterDropdown(props: FilterDropdownProps) {
  function changeFilter(partialFilter: Partial<HypothesesFilter>) {
    const combined = {
      ...props.filter,
      ...partialFilter,
    }
    props.onFilterChange(combined)
  }

  const filterActive = Object.values(props.filter).some((it) => it)

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        {props.children(filterActive)}
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel inset>Filter</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuCheckboxItem
          checked={props.filter.hideValidated}
          onCheckedChange={(c) => changeFilter({ hideValidated: c })}
        >
          Hide Validated
        </DropdownMenuCheckboxItem>
        <DropdownMenuCheckboxItem
          checked={props.filter.hideRefuted}
          onCheckedChange={(c) => changeFilter({ hideRefuted: c })}
        >
          Hide Refuted
        </DropdownMenuCheckboxItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
