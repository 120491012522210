import { useLoaderData, useParams } from 'react-router-dom'
import { Canvas } from './typedef'

export function useSelectedCanvas(): {
  selectedCanvas: Canvas | null
  availableCanvases: Canvas[]
} {
  const params = useParams<{ canvasId: string }>()

  const { canvases } = useLoaderData() as {
    canvases: Canvas[]
  }

  const selectedCanvas =
    canvases.find((c) => c.canvas_id === params.canvasId) || null

  return {
    selectedCanvas,
    availableCanvases: canvases || [],
  }
}
