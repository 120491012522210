import { useSelectedCanvas } from './useSelectedCanvas'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Cog } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { CanvasForm } from './canvas-form'
import { CanvasIntent } from './canvas-form.typedef'
import {
  Drawer,
  DrawerContent,
  DrawerContentWrapper,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '@/components/ui/drawer'

export function CanvasUpdateModal() {
  const { selectedCanvas } = useSelectedCanvas()

  if (!selectedCanvas) {
    return null
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant={'ghost'} className="text-slate-300">
          <Cog className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Update Canvas</DialogTitle>
        </DialogHeader>
        <CanvasForm
          onSubmit={() => {}}
          existingCanvas={selectedCanvas}
          mode={CanvasIntent.Update}
        />
      </DialogContent>
    </Dialog>
  )
}

export function CanvasUpdateDrawer() {
  const { selectedCanvas } = useSelectedCanvas()

  if (!selectedCanvas) {
    return null
  }

  return (
    <Drawer>
      <DrawerTrigger asChild>
        <Button variant={'outline'} className="text-slate-600">
          <Cog className="h-4 w-4" />
        </Button>
      </DrawerTrigger>
      <DrawerContent>
        <DrawerHeader>
          <DrawerTitle>Update Canvas</DrawerTitle>
        </DrawerHeader>
        <DrawerContentWrapper>
          <CanvasForm
            onSubmit={() => {}}
            existingCanvas={selectedCanvas}
            mode={CanvasIntent.Update}
          />
        </DrawerContentWrapper>
      </DrawerContent>
    </Drawer>
  )
}
