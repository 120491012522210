import { BMCKey } from './typedef'
import * as localforage from 'localforage'

export type HypothesesFilter = {
  hideRefuted: boolean
  hideValidated: boolean
}

function getLocalStorageFilterKey(canvasId: string, bmcColumn: BMCKey): string {
  return `${canvasId}:${bmcColumn}:filter`
}

async function storeColumnFilter(
  canvasId: string,
  bmcColumn: BMCKey,
  filter: HypothesesFilter,
) {
  await localforage.setItem(
    getLocalStorageFilterKey(canvasId, bmcColumn),
    filter,
  )
}

async function deleteAllFilters(canvasId: string) {
  const allKeys: BMCKey[] = [
    'channels',
    'cost_structure',
    'customer_relationships',
    'customer_segments',
    'key_activities',
    'key_partners',
    'key_resources',
    'revenue_streams',
    'value_propositions',
  ]

  await Promise.all(
    allKeys.map((key) =>
      localforage.removeItem(getLocalStorageFilterKey(canvasId, key)),
    ),
  )
}

async function getColumnFilter(
  canvasId: string,
  bmcColumn: BMCKey,
): Promise<HypothesesFilter | null> {
  const filter = await localforage.getItem<HypothesesFilter>(
    getLocalStorageFilterKey(canvasId, bmcColumn),
  )

  return filter || null
}

export const columnSettingsService = {
  storeColumnFilter,
  getColumnFilter,
  deleteAllFilters,
}
