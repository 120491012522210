import { uuid } from '@/lib/utils'
import { onlineHypothesesService } from './hypothesesService.online'
import { toast } from 'sonner'
import { BMCKey, Hypotheses, HypothesesState } from './typedef'
import * as localforage from 'localforage'
import { LS_DEMO_CANVAS_KEY } from '@/canvases/canvasService.local'
import { nanoid } from 'nanoid'

const LOCAL_HYPOTHESES_STORAGE_KEY = 'local_hypotheses'

async function getHypotheses(): Promise<Hypotheses[]> {
  const storedHypotheses = await localforage.getItem<Hypotheses[]>(
    LOCAL_HYPOTHESES_STORAGE_KEY,
  )
  return storedHypotheses || []
}

async function saveHypotheses(hypotheses: Hypotheses[]) {
  await localforage.setItem(LOCAL_HYPOTHESES_STORAGE_KEY, hypotheses)
}

export async function deleteAllLocalHypotheses() {
  await localforage.removeItem(LOCAL_HYPOTHESES_STORAGE_KEY)
}

export const localHypothesesService: typeof onlineHypothesesService = {
  async fetchForCanvas(canvasId: string): Promise<{
    success: boolean
    hypotheses: Hypotheses[]
  }> {
    const hypotheses = await getHypotheses()

    let filtered = hypotheses.filter((h) => h.canvas_id === canvasId)

    if (filtered.length === 0 && canvasId === LS_DEMO_CANVAS_KEY) {
      filtered = getInitialDemoHypotheses()
      await saveHypotheses(filtered)
    }

    return {
      hypotheses: filtered,
      success: true,
    }
  },

  async deleteHypotheses(hypothesesId: string): Promise<{ success: boolean }> {
    let hypotheses = await getHypotheses()
    hypotheses = hypotheses.filter((h) => h.hypotheses_id !== hypothesesId)
    await saveHypotheses(hypotheses)
    return { success: true }
  },

  async minorUpdate(
    hypothesesId: string,
    updates: Pick<Hypotheses, 'content' | 'status'>,
  ): Promise<{ success: boolean }> {
    let hypotheses = await getHypotheses()

    hypotheses = hypotheses.map((h) => {
      if (h.hypotheses_id === hypothesesId) {
        return { ...h, ...updates, updated_at: new Date().toISOString() }
      }
      return h
    })

    await saveHypotheses(hypotheses)

    return { success: true }
  },

  async add(
    hypotheses: Pick<
      Hypotheses,
      'content' | 'bmc_column' | 'canvas_id' | 'user_id'
    >,
    fakeCreatedAt?: Date,
  ): Promise<{ success: boolean }> {
    const newHypothesis: Hypotheses = {
      ...hypotheses,
      hypotheses_id: uuid(),
      status: HypothesesState.ToValidate,
      created_at: fakeCreatedAt?.toISOString() || new Date().toISOString(),
      updated_at: new Date().toISOString(),
    }
    const allHypotheses = await getHypotheses()

    // show warning every 10 hypotheses
    if (allHypotheses.length % 10 === 0) {
      setTimeout(() => {
        toast.warning(`If you want to save your progress, please log in.`, {
          duration: 10000,
        })
      }, 3000)
    }

    allHypotheses.push(newHypothesis)

    await saveHypotheses(allHypotheses)

    return { success: true }
  },

  async majorUpdate(
    hypothesesId: string,
    hypotheses: Pick<Hypotheses, 'content'>,
  ): Promise<{ success: boolean }> {
    const allHypotheses = await getHypotheses()

    const originalHypothesis = allHypotheses.find(
      (h) => h.hypotheses_id === hypothesesId,
    )

    if (originalHypothesis) {
      await this.minorUpdate(hypothesesId, {
        status: HypothesesState.Refuted,
        content: originalHypothesis.content,
      })

      return this.add(
        {
          content: hypotheses.content,
          bmc_column: originalHypothesis.bmc_column,
          canvas_id: originalHypothesis.canvas_id,
          user_id: originalHypothesis.user_id,
        },
        new Date(originalHypothesis.created_at),
      )
    }

    return { success: false }
  },
}

function getInitialDemoHypotheses(): Hypotheses[] {
  const partials: Pick<Hypotheses, 'bmc_column' | 'content' | 'status'>[] = [
    {
      bmc_column: 'key_partners',
      content: 'Rental Hosts/Super Hosts',
      status: HypothesesState.Validated,
    },
    {
      bmc_column: 'key_partners',
      content: 'Landlords',
      status: HypothesesState.Refuted,
    },
    {
      bmc_column: 'key_partners',
      content: 'Insurance Companies',
      status: HypothesesState.Validated,
    },
    {
      bmc_column: 'key_partners',
      content: 'Tourism Companies',
      status: HypothesesState.ToValidate,
    },
    {
      bmc_column: 'key_activities',
      content: 'Reduce Risks for Hosts & Guests',
      status: HypothesesState.Validated,
    },
    {
      bmc_column: 'key_activities',
      content: 'Reduce friction for Hosts & Guests',
      status: HypothesesState.Validated,
    },
    {
      bmc_column: 'key_activities',
      content: 'Promote certain cities',
      status: HypothesesState.Validated,
    },
    {
      bmc_column: 'key_activities',
      content: 'Provide a feedback mechanism',
      status: HypothesesState.ToValidate,
    },
    {
      bmc_column: 'key_resources',
      content: 'Content generated by Users',
      status: HypothesesState.Validated,
    },
    {
      bmc_column: 'key_resources',
      content: 'Platform Architecture',
      status: HypothesesState.Validated,
    },
    {
      bmc_column: 'key_resources',
      content: 'The apartment listings',
      status: HypothesesState.Refuted,
    },
    {
      bmc_column: 'key_resources',
      content: 'Brand, App',
      status: HypothesesState.Validated,
    },
    {
      bmc_column: 'value_propositions',
      content: 'Income generation for Hosts',
      status: HypothesesState.Validated,
    },
    {
      bmc_column: 'value_propositions',
      content: 'Meeting new people for Hosts/Guests',
      status: HypothesesState.Validated,
    },
    {
      bmc_column: 'value_propositions',
      content: 'Calendar/Booking Management for Hosts',
      status: HypothesesState.Validated,
    },
    {
      bmc_column: 'value_propositions',
      content: 'Low cost and low friction for Guests',
      status: HypothesesState.Validated,
    },
    {
      bmc_column: 'value_propositions',
      content: 'Authentic Local Experience for Guests',
      status: HypothesesState.Refuted,
    },
    {
      bmc_column: 'customer_relationships',
      content: 'Resolve issues for Hosts',
      status: HypothesesState.Refuted,
    },
    {
      bmc_column: 'customer_relationships',
      content: 'Manage the interactions between Guests/Hosts',
      status: HypothesesState.Refuted,
    },
    {
      bmc_column: 'customer_relationships',
      content: 'Guide Guests during their first visit',
      status: HypothesesState.ToValidate,
    },
    {
      bmc_column: 'channels',
      content: 'Digital Ads',
      status: HypothesesState.ToValidate,
    },
    {
      bmc_column: 'channels',
      content: 'Word of Mouth',
      status: HypothesesState.Validated,
    },
    {
      bmc_column: 'channels',
      content: 'Content Marketing',
      status: HypothesesState.Validated,
    },
    {
      bmc_column: 'channels',
      content: 'Referrals/Promotions',
      status: HypothesesState.Validated,
    },
    {
      bmc_column: 'customer_segments',
      content: 'Business Guests',
      status: HypothesesState.Refuted,
    },
    {
      bmc_column: 'customer_segments',
      content: 'Leasure/Vacation Guests',
      status: HypothesesState.Validated,
    },
    {
      bmc_column: 'customer_segments',
      content: 'Local Guests',
      status: HypothesesState.Refuted,
    },
    {
      bmc_column: 'customer_segments',
      content: 'Foreign Guests',
      status: HypothesesState.Validated,
    },
    {
      bmc_column: 'customer_segments',
      content: 'Owned Apartment Hosts',
      status: HypothesesState.Validated,
    },
    {
      bmc_column: 'customer_segments',
      content: 'Apartment Complex Hosts',
      status: HypothesesState.ToValidate,
    },
    {
      bmc_column: 'cost_structure',
      content: 'Customer Acquisition Costs',
      status: HypothesesState.Validated,
    },
    {
      bmc_column: 'cost_structure',
      content: 'Legal & Insurance Costs',
      status: HypothesesState.Refuted,
    },
    {
      bmc_column: 'cost_structure',
      content: 'Customer Support',
      status: HypothesesState.ToValidate,
    },
    {
      bmc_column: 'revenue_streams',
      content: 'Transaction Fees',
      status: HypothesesState.Validated,
    },
    {
      bmc_column: 'revenue_streams',
      content: 'Rental Guests: 5-15%',
      status: HypothesesState.Validated,
    },
    {
      bmc_column: 'revenue_streams',
      content: 'Rental Hosts: 3-5%',
      status: HypothesesState.Validated,
    },
    {
      bmc_column: 'revenue_streams',
      content: 'Event Hosts: 20%',
      status: HypothesesState.ToValidate,
    },
  ]

  return partials.map(
    (partialHypo): Hypotheses => ({
      content: partialHypo.content,
      status: partialHypo.status,
      bmc_column: partialHypo.bmc_column as BMCKey,
      created_at: new Date().toISOString(),
      canvas_id: LS_DEMO_CANVAS_KEY,
      hypotheses_id: nanoid(),
      user_id: 'local',
      updated_at: new Date().toISOString(),
    }),
  ) satisfies Hypotheses[]
}
