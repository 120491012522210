import { BMCKey } from '@/hypotheses/typedef'

function getLabel(k: BMCKey): string {
  switch (k) {
    case 'key_activities':
      return 'Key Activities'
    case 'customer_relationships':
      return 'Cust. Relationships'
    case 'key_partners':
      return 'Key Partnerships'
    case 'key_resources':
      return 'Key Resources'
    case 'value_propositions':
      return 'Value Propositions'
    case 'channels':
      return 'Channels'
    case 'customer_segments':
      return 'Customer Segments'
    case 'cost_structure':
      return 'Cost Structure'
    case 'revenue_streams':
      return 'Revenue Streams'
    default:
      throw Error('Unknown key: ' + k)
  }
}

export const bmcHelper = {
  getLabel,
}
