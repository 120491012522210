import { Navigate, useLoaderData } from 'react-router-dom'
import { useUserStore } from '@/stores/user'
import { Canvas } from '@/canvases/typedef'

export function IndexPage() {
  const user = useUserStore((state) => state.user)
  const { canvases } = useLoaderData() as {
    canvases: Canvas[]
  }

  const canvasId = user?.user_metadata.lastSeenCanvas || canvases[0].canvas_id

  return <Navigate replace to={`/bmc/${canvasId}`} />
}
