import { useSingletonStore, SINGLETON_MODALS } from '@/stores/singletons'

export function OpenFeedbackButton(props: {
  children: (openFeedback: () => void) => React.ReactNode
}) {
  const setOpenSingletonModal = useSingletonStore(
    (state) => state.setOpenSingletonModal,
  )

  function openFeedback() {
    setOpenSingletonModal(SINGLETON_MODALS.FEEDBACK)
  }

  return <>{props.children(openFeedback)}</>
}
