import { useUserStore } from '@/stores/user'
import { HypothesesInput } from './hypotheses-input'
import { getHypothesesService } from './hypothesesService'
import { useOptimistic } from '@/hooks/useOptimistic'
import { Hypotheses } from './typedef'

type HypothesesEditProps = {
  hypotheses: Hypotheses
  onClose: () => void
  optimisticUpdateContent: (content: string) => void
  undoOptimistic: () => void
  updateMode: 'major_update' | 'minor_update'
}

export function HypothesesEdit(props: HypothesesEditProps) {
  const user = useUserStore((state) => state.user)
  const revalidator = useOptimistic(() => props.undoOptimistic())

  async function updateHypotheses(
    _event: React.FormEvent<HTMLFormElement>,
    content: string,
  ) {
    if (!content) {
      return { success: false }
    }

    props.optimisticUpdateContent(content)
    props.onClose()

    let toReturn = null

    if (props.updateMode === 'minor_update') {
      toReturn = await getHypothesesService(!user).minorUpdate(
        props.hypotheses.hypotheses_id,
        {
          status: props.hypotheses.status,
          content,
        },
      )
    } else {
      toReturn = await getHypothesesService(!user).majorUpdate(
        props.hypotheses.hypotheses_id,
        {
          content,
        },
      )
    }

    revalidator.revalidate()

    return toReturn
  }

  return (
    <HypothesesInput
      keepOpen={false}
      handleClose={props.onClose}
      onSubmit={updateHypotheses}
      onNext={() => {}}
      defaultContent={props.hypotheses.content}
    />
  )
}
