import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { nanoid } from 'nanoid'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export async function sleep(ms = 1000) {
  await new Promise((r) => setTimeout(r, ms))
}

export function uuid() {
  return nanoid()
}
