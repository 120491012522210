import { Button } from '@/components/ui/button'
import {
  Form as FormUI,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { zodResolver } from '@hookform/resolvers/zod'
import { Loader2, Trash2 } from 'lucide-react'
import { FormEvent, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  redirect,
  useNavigate,
  useNavigation,
  useSubmit,
} from 'react-router-dom'
import { z } from 'zod'
import { getCanvasService } from './canvasService'
import { CanvasIntent } from './canvas-form.typedef'
import { Canvas } from './typedef'

const formSchema = z.object({
  title: z.string().min(1),
  description: z.string().default(''),
  intent: z.enum([CanvasIntent.Create, CanvasIntent.Update]),
  canvasId: z.string().optional(),
})

type CanvasFormProps = {
  mode: CanvasIntent.Update | CanvasIntent.Create
  existingCanvas?: Canvas
  onSubmit: () => void
}
export function CanvasForm(props: CanvasFormProps) {
  const submit = useSubmit()
  const navigation = useNavigation()
  const navigate = useNavigate()
  const [loadingDeletion, setLoadingDeletion] = useState(false)

  const isLoading = navigation.state !== 'idle'
  const isUpdate = props.mode === CanvasIntent.Update

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: props.existingCanvas?.title || '',
      description: props.existingCanvas?.description || '',
      intent: props.mode,
      canvasId: props.existingCanvas?.canvas_id,
    },
  })

  async function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()

    const currentForm = event.currentTarget

    form.handleSubmit(() => {
      submit(currentForm, {
        method: 'post',
        action: '/',
      })

      props.onSubmit()
    })(event)
  }

  async function handleDelete() {
    if (!confirm(`Are you sure?`) || !props.existingCanvas) {
      return
    }

    setLoadingDeletion(true)

    const { success } = await getCanvasService().deleteCanvas(
      props.existingCanvas?.canvas_id,
    )

    if (success) {
      navigate('/', {
        replace: true,
      })
    }
    setLoadingDeletion(false)
  }

  return (
    <div className="w-full max-w-full">
      <FormUI {...form}>
        <form onSubmit={onSubmit} className="space-y-12">
          <input type="hidden" name="intent" value={props.mode} />
          {props.existingCanvas?.canvas_id && (
            <input
              type="hidden"
              value={props.existingCanvas?.canvas_id}
              name="canvasId"
            />
          )}

          <div className="relative space-y-4 rounded-md shadow-sm">
            <FormField
              name="title"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Title</FormLabel>
                  <FormControl>
                    <Input placeholder="My Cool Canvas" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="description"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <Textarea placeholder="optional" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="flex space-x-2">
            <Button disabled={isLoading} className="w-full">
              {isLoading ? (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              ) : isUpdate ? (
                'Update Canvas'
              ) : (
                'Create Canvas'
              )}
            </Button>

            {isUpdate && (
              <Button onClick={handleDelete} variant={'destructive'}>
                {loadingDeletion ? (
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                ) : (
                  <Trash2 className="h-4 w-4" />
                )}
              </Button>
            )}
          </div>
        </form>
      </FormUI>
    </div>
  )
}

CanvasForm.action = async function rootAction(args: {
  request: Request
}): Promise<Response | null> {
  const formData = await args.request.formData()

  const { intent, description, title, canvasId } = Object.fromEntries(
    formData,
  ) as {
    intent: CanvasIntent
    description: string
    title: string
    canvasId?: string
  }

  if (!intent) {
    throw new Response('CANVAS_FORM_ACTION_USE_INTENT', {
      status: 500,
      statusText: 'Some code is not wired up correctly. Sorry for this!',
    })
  }

  /** Descrition may be "", so we only check against undefined/null */
  if (description == null || !title) {
    throw new Response('CANVAS_FORM_ACTION_USE_DESC_AND_TITLE', {
      status: 500,
      statusText: 'Some code is not wired up correctly. Sorry for this!',
    })
  }

  if (intent === CanvasIntent.Create) {
    const { success, canvas } = await getCanvasService().create(
      title!,
      description!,
    )

    if (!success) {
      return null
    }

    return redirect(`/bmc/${canvas.canvas_id}`)
  }

  if (!canvasId) {
    throw new Response('CANVAS_FORM_ACTION_SEND_CANVASID', {
      status: 500,
      statusText: 'Some code is not wired up correctly. Sorry for this!',
    })
  }

  if (intent === CanvasIntent.Update) {
    await getCanvasService().updateCanvas(canvasId, {
      title: title!,
      description: description!,
    })

    return null
  }

  return null
}
