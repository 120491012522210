import { User } from '@supabase/supabase-js';
import { create } from 'zustand';

interface UserState {
  user: User | null;
  setUser: (u: User | null) => void;
}

export const useUserStore = create<UserState>()(function (set) {
  return {
    user: null,
    setUser: (u: User | null) => set(() => ({ user: u })),
  };
});
