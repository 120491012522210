import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Plus } from 'lucide-react'
import { useState } from 'react'
import { CanvasForm } from '.'
import { CanvasIntent } from './canvas-form.typedef'
import {
  Drawer,
  DrawerContent,
  DrawerContentWrapper,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '@/components/ui/drawer'

export function CreateCanvasModal() {
  const [open, setOpen] = useState(false)

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant={'secondary'} className="w-full">
          <Plus className="mr-2 h-4 w-4" /> Create Canvas
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create Canvas</DialogTitle>
        </DialogHeader>
        <CanvasForm
          onSubmit={() => setOpen(false)}
          mode={CanvasIntent.Create}
        />
      </DialogContent>
    </Dialog>
  )
}

export function CreateCanvasDrawer() {
  const [open, setOpen] = useState(false)

  return (
    <Drawer open={open} onOpenChange={setOpen}>
      <DrawerTrigger asChild>
        <Button variant={'secondary'} className="w-full">
          <Plus className="mr-2 h-4 w-4" /> Create Canvas
        </Button>
      </DrawerTrigger>
      <DrawerContent>
        <DrawerContentWrapper>
          <DrawerHeader>
            <DrawerTitle>Create Canvas</DrawerTitle>
          </DrawerHeader>
          <CanvasForm
            onSubmit={() => setOpen(false)}
            mode={CanvasIntent.Create}
          />
        </DrawerContentWrapper>
      </DrawerContent>
    </Drawer>
  )
}
