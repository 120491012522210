import './index.css'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom'
import { Root } from './Root'
import { BmcPage } from './bmc/bmc-page'
import { IndexPage } from './bmc/index-page'
import { CanvasForm } from './canvases/canvas-form'
import { ErrorElement } from './errors/error-element'
import './sentry'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    loader: Root.loader,
    action: CanvasForm.action,
    errorElement: <ErrorElement />,
    children: [
      {
        index: true,
        loader: Root.loader,
        element: <IndexPage />,
      },
      {
        path: 'bmc',
        element: <Navigate to="/" replace />,
      },
      {
        path: '/bmc/:canvasId',
        loader: BmcPage.loader,
        element: <BmcPage />,
        errorElement: <ErrorElement />,
      },

      {
        path: '/error',
        element: <ErrorElement />,
      },
    ],
  },
])

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
)
