import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  Drawer,
  DrawerContent,
  DrawerContentWrapper,
  DrawerHeader,
  DrawerTitle,
} from '@/components/ui/drawer'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import { Textarea } from '@/components/ui/textarea'
import { useTailwindScreenSize } from '@/hooks/useTailwindScreenSize'
import { SINGLETON_MODALS, useSingletonStore } from '@/stores/singletons'
import { useUserStore } from '@/stores/user'
import { supabase } from '@/supabase'
import { zodResolver } from '@hookform/resolvers/zod'
import { Bug, FileQuestion, Lightbulb, Loader2 } from 'lucide-react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { z } from 'zod'

enum FeedbackCategory {
  Bug = 'bug',
  Idea = 'idea',
  Other = 'other',
}

export function Feedback() {
  const isMediumScreen = useTailwindScreenSize('md')
  const singletonStore = useSingletonStore()
  const [isLoading, setIsLoading] = useState(false)

  const isOpen = singletonStore.openSingletonModal === SINGLETON_MODALS.FEEDBACK

  const Comp = isMediumScreen ? FeedbackPopUp : FeedbackDrawer

  async function submit(
    content: string,
    category: FeedbackCategory,
    email?: string,
  ) {
    if (!content) {
      return
    }

    setIsLoading(true)

    const { error } = await supabase.from('feedback').insert({
      feedback: content,
      email,
      category,
    })

    setIsLoading(false)
    if (error) {
      toast.error(`Something went wrong: ${error.message}`)
    } else {
      toast.success(`Thank you so much! I hope you enjoy the app.`)
      singletonStore.setOpenSingletonModal('')
    }
  }

  return (
    <Comp
      isOpen={isOpen}
      setIsOpen={(o) =>
        singletonStore.setOpenSingletonModal(o ? SINGLETON_MODALS.FEEDBACK : '')
      }
      submit={submit}
      isLoading={isLoading}
    />
  )
}

type FeedbackProps = {
  isOpen: boolean
  setIsOpen: (b: boolean) => void
  submit: (
    content: string,
    category: FeedbackCategory,
    email?: string,
  ) => Promise<void>
  isLoading: boolean
}

export function FeedbackDrawer(props: FeedbackProps) {
  return (
    <Drawer open={props.isOpen} onOpenChange={(o) => props.setIsOpen(o)}>
      <DrawerContent>
        <DrawerContentWrapper>
          <DrawerHeader>
            <DrawerTitle>I'd love to hear your feedback!</DrawerTitle>
          </DrawerHeader>
          <FeedbackForm isLoading={props.isLoading} onSubmit={props.submit} />
        </DrawerContentWrapper>
      </DrawerContent>
    </Drawer>
  )
}

export function FeedbackPopUp(props: FeedbackProps) {
  return (
    <Dialog open={props.isOpen} onOpenChange={(o) => props.setIsOpen(o)}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>I'd love to hear your feedback!</DialogTitle>
        </DialogHeader>
        <FeedbackForm isLoading={props.isLoading} onSubmit={props.submit} />
      </DialogContent>
    </Dialog>
  )
}

const formSchema = z.object({
  email: z.string().email('Please provide a valid email.').optional(),
  feedback: z.string().min(10, 'Please provide meaningful feedback :)'),
  category: z.enum([
    FeedbackCategory.Bug,
    FeedbackCategory.Idea,
    FeedbackCategory.Other,
  ]),
})

function FeedbackForm(props: {
  isLoading: boolean
  onSubmit: (
    feedback: string,
    category: FeedbackCategory,
    email?: string,
  ) => Promise<void>
}) {
  const user = useUserStore((state) => state.user)

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      category: FeedbackCategory.Other,
      email: undefined,
      feedback: '',
    },
  })

  function handleSubmit(values: z.infer<typeof formSchema>) {
    props.onSubmit(values.feedback, values.category, values.email || undefined)
  }

  return (
    <Form {...form}>
      <form
        className="space-y-12 py-4"
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <div className="space-y-6">
          <FormField
            name="feedback"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Your Feedback</FormLabel>
                <FormControl>
                  <Textarea rows={6} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {!user && (
            <FormField
              name="email"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email (Optional)</FormLabel>
                  <FormControl>
                    <Input type="email" {...field} />
                  </FormControl>
                  <FormDescription>
                    Just in case you want to hear back from me.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
          <FormField
            name="category"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Category</FormLabel>
                <FormControl>
                  <RadioGroup
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    className="flex w-full max-w-96 justify-between"
                  >
                    <FormItem className="flex items-end space-x-3">
                      <FormControl>
                        <RadioGroupItem value={FeedbackCategory.Bug} />
                      </FormControl>
                      <FormLabel className="flex items-center space-x-1">
                        <Bug className="mr-1 h-4 w-4" /> Bug
                      </FormLabel>
                    </FormItem>
                    <FormItem className="flex items-end space-x-3">
                      <FormControl>
                        <RadioGroupItem value={FeedbackCategory.Idea} />
                      </FormControl>

                      <FormLabel className="flex items-center space-x-1">
                        <Lightbulb className="mr-1 h-4 w-4" /> Idea
                      </FormLabel>
                    </FormItem>
                    <FormItem className="flex items-end space-x-3">
                      <FormControl>
                        <RadioGroupItem value={FeedbackCategory.Other} />
                      </FormControl>
                      <FormLabel className="flex items-center space-x-1">
                        <FileQuestion className="mr-1 h-4 w-4" /> Other
                      </FormLabel>
                    </FormItem>
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <Button type="submit" value={'default'}>
          {props.isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
          Send Feedback
        </Button>
      </form>
    </Form>
  )
}
