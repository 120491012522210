import { compareAsc } from 'date-fns'
import { bmcHelper } from './bmcHelper'
import { ScrollArea } from '@/components/ui/scroll-area'
import { BMCCreateInput } from './bmc-create-input'
import { useEffect, useState } from 'react'
import { Button } from '@/components/ui/button'
import { Filter, Plus } from 'lucide-react'
import { HypothesesItem } from '@/hypotheses/HypothesesItem'
import { useSingletonStore } from '@/stores/singletons'
import { cn } from '@/lib/utils'
import {
  HypothesesFilter,
  columnSettingsService,
} from '../hypotheses/columnSettings'
import { useParams } from 'react-router-dom'
import {
  BMCKey,
  Hypotheses,
  HypothesesToRender,
  HypothesesState,
} from '@/hypotheses/typedef'
import { HypothesesDisplay } from '@/hypotheses/hypotheses-display'
import { FilterDropdown } from './filter-dropdown'

type BMContentProps = {
  fieldTitle: BMCKey
  hypotheses?: Record<BMCKey, Hypotheses[]>
  className?: string
}
export function BMCContent(props: BMContentProps) {
  const params = useParams() as { canvasId: string }

  const openId = `add_hypotheses_${props.fieldTitle}`
  const { setOpenSingletonInput, openSingletonInput, nextHypothesesInput } =
    useSingletonStore()

  const [filter, setFilter] = useState<HypothesesFilter>({
    hideRefuted: false,
    hideValidated: false,
  })

  useEffect(() => {
    async function fx() {
      const storedFilter = await columnSettingsService.getColumnFilter(
        params.canvasId,
        props.fieldTitle,
      )
      if (storedFilter) {
        setFilter(storedFilter)
      }
    }

    fx()
  }, [props.fieldTitle, params.canvasId])

  async function onFilterChange(f: HypothesesFilter) {
    await columnSettingsService.storeColumnFilter(
      params.canvasId,
      props.fieldTitle,
      f,
    )
    setFilter(f)
  }

  const [optimisticHypotheses, setOptimisticHypotheses] = useState<
    HypothesesToRender[]
  >([])
  const passedHypotheses = (props.hypotheses?.[props.fieldTitle].slice() || [])
    .sort((a, b) => compareAsc(new Date(a.created_at), new Date(b.created_at)))
    .filter((hypo) => {
      const isRefuted = hypo.status === HypothesesState.Refuted

      return !isRefuted || !filter.hideRefuted
    })
    .filter((hs) => {
      const isValidated = hs.status === HypothesesState.Validated

      return !isValidated || !filter.hideValidated
    })

  return (
    <div
      id={props.fieldTitle}
      className={cn('min-h-48 py-4 lg:min-h-0 lg:p-0', props.className)}
    >
      <div className="flex h-full w-full flex-col overflow-hidden">
        {' '}
        <div className="flex max-h-[50px] flex-grow-0 items-center justify-between px-4 pt-4 ">
          <h4 className="text-sm font-semibold uppercase tracking-wide text-slate-600/70">
            {bmcHelper.getLabel(props.fieldTitle)}
          </h4>
          <div className="flex justify-end space-x-3 lg:space-x-1">
            <Button
              variant={'secondary'}
              size={'xs'}
              onClick={(e) => {
                e.stopPropagation()
                setOpenSingletonInput(openId)
              }}
              className="bg-emerald-600/70 text-white hover:bg-emerald-500"
            >
              <Plus className="h-4 w-4" />
            </Button>
            <FilterDropdown filter={filter} onFilterChange={onFilterChange}>
              {(active) => (
                <Button
                  disabled={passedHypotheses.length === 0}
                  variant={'secondary'}
                  size={'xs'}
                  className={cn(
                    'bg-slate-100 text-slate-600 hover:bg-slate-200 disabled:bg-white disabled:opacity-50',
                    {
                      'bg-blue-400/60 text-white hover:bg-blue-300/80': active,
                    },
                  )}
                >
                  <Filter className="h-3.5 w-3.5" />
                </Button>
              )}
            </FilterDropdown>
          </div>
        </div>
        <ScrollArea className="flex-grow px-2">
          <div className="pb-8 pt-4">
            <ul>
              {passedHypotheses.map((hypo) => (
                <HypothesesItem hypotheses={hypo} key={hypo.hypotheses_id} />
              ))}
              {optimisticHypotheses.map((hypo) => (
                <li key={hypo.hypotheses_id}>
                  <HypothesesDisplay
                    hypotheses={hypo}
                    key={hypo.hypotheses_id}
                  />
                </li>
              ))}
              {openSingletonInput !== openId &&
                passedHypotheses.length === 0 &&
                optimisticHypotheses.length === 0 && (
                  <li className="pl-2 lg:hidden">
                    <p className="text-slate-400/60">Empty</p>{' '}
                  </li>
                )}
            </ul>
            {openSingletonInput === openId && (
              <div className="w-full px-2 pr-2 pt-2">
                <BMCCreateInput
                  fieldTitle={props.fieldTitle}
                  addHypotheses={(h) =>
                    setOptimisticHypotheses((hs) => hs.concat(h))
                  }
                  handleCancel={() => setOpenSingletonInput('')}
                  undoOptimistic={() => setOptimisticHypotheses([])}
                  onNext={() => nextHypothesesInput(props.fieldTitle)}
                />
              </div>
            )}
          </div>
        </ScrollArea>
      </div>
    </div>
  )
}
